
import { Component, Vue } from "vue-property-decorator";
import { NavBar, Icon, Cell, CellGroup, Dialog } from "vant";
import HotelOrderInfo from "@/components/hotelOrderInfo/index.vue";

import FootHotelPrice from "@/components/footHotelPrice/index.vue";

import { fectHotelOrder, fectHotelOrderPay } from "@/api/hotel/index.api";

@Component({
  components: {
    [NavBar.name]: NavBar,

    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Dialog.name]: Dialog,

    HotelOrderInfo,
    FootHotelPrice,
  },
})
export default class HotelConfirm extends Vue {
  public hotelInfoData: any = {};

  get getTotalPrice() {
    return this.hotelInfoData.TotalPrice * this.hotelInfoData.roomNum;
  }

  public async handleConfirm() {
    let { data } = await fectHotelOrder(this.hotelInfoData.queryObj);
    let OrderNumber = JSON.parse((data as any).ReturnJson);
    if (OrderNumber) {
      let res = await fectHotelOrderPay({ OrderNumber: OrderNumber });
      let payData = JSON.parse(res.data.ReturnJson);
      this.onBridgeReady(payData, OrderNumber);
    }
  }
  public onBridgeReady(payData, OrderNumber) {
    // eslint-disable-line
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: payData.appId, //公众号ID，由商户传入
        timeStamp: payData.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: payData.nonceStr, //随机串
        package: payData.package,
        signType: payData.signType, //微信签名方式：
        paySign: payData.paySign, //微信签名
      },
      async (res) => {
        if (res.errMsg) {
          await Dialog.alert({
            title: "提示",
            message: res.errMsg,
          });
        }
        await this.$router.push({
          path: "/orderDetail",
          query: { OrderNumber: OrderNumber },
        });
      }
    );
  }

  created() {
    this.hotelInfoData =
      (this.$route.query &&
        this.$route.query.hotelInfoData &&
        JSON.parse(this.$route.query.hotelInfoData + "")) ||
      {};
  }
}
