
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Icon, Button, Popup, Cell, CellGroup } from 'vant';

@Component({
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  }
})
export default class FootHotelPrice extends Vue {
  @Prop({ type: Object, default: () => ({}) }) hotelInfoData;
  @Prop({ type: Number, default: 0 }) getTotalPrice;
  @Prop({ type: Number, default: 0 }) roomNum;
  @Prop({ type: String, default: '下一步' }) btnTitle;

  public isShowPrice: boolean = false;
  handleClick() {
    this.$emit('click');
  }
  public getDays(date) {
    return this.$moment(date).format('MM月DD日');
  }
}
